import {Player} from './player';
import {PageOfTeam} from './page-of-team';
import {Picture} from '@core/services/picture';
import {IStadium} from '@shared/models/stadium';
import {Team} from '@api/models/team/team';
import {UrlService} from '@utils/services/url/url.service';
import {PickerItem, PickerItemType} from '@lib-modules/picker/components/picker-item/picker-item.component';
import {ImageSubType} from '@api/models/image/image-type';
import {IPicture} from '@api/models/image/picture';
import {EntityType} from '@lib/models/entity/entity-type';
import {Entity} from '@lib/models/entity/entity';

interface TeamUIName {
  league_name: string;
  state_name: string;
  country_name: string;
  followers: string;
}

interface TeamFrontendSettings {
  allow_iframe_usage: boolean;
  colors: { background: string, foreground: string }; // Foreground is currently not used
  custom_pages: [];
  domain: string;
  website_header: boolean;
}

export const teamLogoPlaceholderImage = 'assets/icons/fan.at/team-dummy-male.svg';

export class AppTeam extends Team {
  id: string;
  clubid: string;
  readable_id: string;
  name: string;
  trainer: {
    firstname: string,
    lastname: string
  };
  trainers: [];
  squad: Player[];
  leagueids: string[];
  logoID: string;
  followers: number;
  pages: PageOfTeam[] = [];
  pictures: Picture[];
  abbreviation: string;
  moderation_type: string;
  frontend_settings: TeamFrontendSettings;
  ui_names: TeamUIName;
  stadium: IStadium;
  hasReporter: boolean;

  static getLogoImageUrl(pictures: Picture[], size: string): string {
    return Picture.getImageOfSizeByString(pictures, size, 'logo', teamLogoPlaceholderImage);
  }

  getLeagueForTable(): string {
    if (this.leagueids == null || this.leagueids.length === 0) {
      return null;
    }

    return this.leagueids[0];
  }

  getUIName(): string {
    if (this.ui_names == null) {
      return null;
    }

    let uiName = null;

    if (this.ui_names.state_name != null && this.ui_names.state_name.length > 0) {
      uiName = this.ui_names.state_name;
    }

    if (this.ui_names.league_name != null && this.ui_names.league_name.length > 0) {
      if (uiName !== null) {
        uiName = uiName + ' · ' + this.ui_names.league_name;
      } else {
        uiName = this.ui_names.league_name;
      }
    }

    return uiName;
  }

  getStateUIName(): string {
    if (this.ui_names == null || !this.ui_names.state_name) {
      return null;
    }

    return this.ui_names.state_name;
  }

  getLeagueUIName(): string {
    if (this.ui_names == null || !this.ui_names.league_name) {
      return null;
    }

    return this.ui_names.league_name;
  }

  getAsPickerItem(): PickerItem {
    let logo: IPicture;
    if (this.pictures.length > 0) {
      logo = this.pictures.find(picture => picture.subtype === ImageSubType.LOGO);
    }
    return {
      objectId: this.id,
      name: this.name,
      abbreviation: this.abbreviation,
      readableId: this.readable_id,
      type: PickerItemType.Team,
      logo: logo,
    };
  }

  public constructor(init?: Partial<Team>) {
    super(init);
    this.trainers = [];
    this.pictures = [];

    Object.assign(this, init);

    this.id = init._id;
    this.hasReporter = this.moderation_type === 'team';

    if (init.pages) {
      this.pages = init.pages.map( page => new PageOfTeam(page) );
    }

    if (init.pictures) {
      this.pictures = init.pictures.map((p) => new Picture(p));
    }

    this.pages.forEach((page) => {
      if (page.isMailPage() && !page.url?.startsWith('mailto:')) {
        page.urlRef = 'mailto:' + page.url;
        return;
      }
      if (page.isPhonePage() && !page.url?.startsWith('tel:')) {
        const trimmedPhoneNumber = page.url.replace(/\s/g, '');
        page.urlRef = 'tel:' + trimmedPhoneNumber;
        return;
      }
      if (!page.url?.startsWith('http')) {
        page.urlRef = UrlService.sanitizeUrl(page.url);
      }
    });
  }

  getLogoImageUrl(size: string): string {
    return AppTeam.getLogoImageUrl(this.pictures, size);
  }

  getAsEntity(): Entity {
    return  new Entity({
      id: this.id,
      title: this.name,
      type: EntityType.Team,
      gender: this.sex,
      leagueName: this.ui_names?.league_name,
      leagueId: this.leagueids?.[0],
      stateName: this.ui_names?.state_name,
      countryName: this.ui_names?.country_name,
      performanceLevel: this.performance_level,
    });
  }
}
