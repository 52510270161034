import {Injectable} from '@angular/core';
import {APIService, ChangeUserPassword} from './api.service';
import {HttpClient} from '@angular/common/http';
import {User} from '@shared/models/user';
import {StateService} from '@core/services/state.service';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {UserHttpService} from '@api/http/user/user-http.service';
import {SnackbarService} from '@core/services/snackbar.service';
import {IUser, LoginType} from '@api/models/user/user';
import {CookieService} from '@utils/services/cookie/cookie.service';
import {CookieName} from '@utils/services/cookie/cookie-name';
import {LibVideoPlayerService} from '@components/video-player/services/lib-video-player.service';
import {SubscriptionsHttpService} from '@api/http/subscriptions/subscriptions-http.service';
import {AnalyticsService} from '@utils/services/analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static readonly INVALID_USERNAME_OR_PASSWORD = 'INVALID_USERNAME_OR_PASSWORD';
  static readonly INVALID_PARAMETERS = 'INVALID_PARAMETERS';
  static readonly OTHER_ERROR = 'ERROR';
  static readonly TOKEN_EXPIRED = 'TOKEN_EXPIRED';

  public currentUserSubject = new BehaviorSubject<User>(null);
  public currentUser: User;
  public digestLoginPromise: Promise<IUser>;

  private route(): string {
    return this.apiService.getBaseURL() + 'users/';
  }
  private authenticationRoute(): string {
    return this.apiService.getBaseURL() + 'users/login/';
  }

  private checkUsernameRoute(username: string): string {
    return this.route() + 'check/username/' + username;
  }

  constructor( private http: HttpClient,
               private appStateService: StateService,
               private apiService: APIService,
               private userHttpService: UserHttpService,
               private snackbar: SnackbarService,
               private router: Router,
               private videoPlayerService: LibVideoPlayerService) {}

  isLoggedIn() {
    if (!StateService.isBrowser) {
      return false;
    }
    const token = CookieService.getCookie(CookieName.ACCESS_TOKEN);
    return !!token;
  }

  private async refreshUser(user: User, showSnackbar = false) {
    if (user) {
      CookieService.setAccessTokenCookie(user.api_access_token);
      this.appStateService.set(this.appStateService.USER_IS_LOGGED_IN, true);
      await this.videoPlayerService.updateSubscriptionInfo(user._id);
      if (showSnackbar) {
        this.snackbar.showSimpleSnackbar('Login erfolgreich!');
      }
    } else {
      CookieService.deleteCookie(CookieName.ACCESS_TOKEN);
      this.appStateService.set(this.appStateService.USER_IS_LOGGED_IN, false);
      this.videoPlayerService.resetSubscriptionInfo();
      if (showSnackbar) {
        this.snackbar.showSimpleSnackbar('Login fehlgeschlagen!');
      }
    }

    this.currentUser = user;
    this.currentUserSubject.next(user);
    this.setAnalyticsInfo();
  }

  private setAnalyticsInfo() {
    AnalyticsService.currentUserLoginType = this.getLoginType();
    AnalyticsService.currentUserHasSubscription = this.videoPlayerService.isSubscribed;
  }

  public get isSubscribed() {
    return this.videoPlayerService.isSubscribed;
  }

  /***************************** HTTP GET FUNCTIONS *****************************/

  checkUsernameAvailability(username: string): Promise<any> {
    return this.http.get(this.checkUsernameRoute(username)).toPromise().catch(error =>
      console.log(error)
    );
  }

  public async digestLoginUser(userId: string, timestamp: number, digest: string): Promise<void> {
    try {
      this.digestLoginPromise = this.userHttpService.postDigestLoginUser(userId, timestamp, digest);
      const response = await this.digestLoginPromise;
      if (!response) {
        this.handleFailedLogin();
        return;
      }
      await this.handleSuccessfulLogin(User.createFromJson(response), false);
    } catch (e) {
      this.handleFailedLogin();
      throw e;
    }
  }

  public async login(email: string, password: string): Promise<User | null> {
    const body = {
      email: email,
      password: password
    };
    const response = await this.http.post(this.authenticationRoute(), body, this.apiService.getJsonOptions()).toPromise()
    if (!response) {
      this.handleFailedLogin(true);
      return null;
    }
    await this.handleSuccessfulLogin(User.createFromJson(response));
    return this.currentUser;
  }

  public async loginFromToken(): Promise<User | null> {
    try {
      const token = CookieService.getCookie(CookieName.ACCESS_TOKEN);
      if (!token) {
        return null;
      }
      const response = await this.userHttpService.getUser();
      const user = User.createFromJson(response);
      await this.handleSuccessfulLogin(user, false);
      return user;
    } catch (e) {
      throw e;
    }
  }

  private async handleSuccessfulLogin(user: User, showSnackbar = true): Promise<void> {
    this.refreshUser(user, showSnackbar);

    this.videoPlayerService.logout.subscribe(() => {
      this.logoutUser();
    });
  }

  private handleFailedLogin(showSnackbar = false): void {
    this.refreshUser(null, showSnackbar);
  }

  public async facebookLogin(token: string): Promise<boolean> {
    try {
      const fbLogin = await this.apiService.postFbLogin(token).toPromise();
      if (fbLogin.success) {
        this.refreshUser(fbLogin.user, true);
        return true;
      } else {
        this.refreshUser(null);
        return false;
      }
    } catch (e) {
      throw e;
    }
  }

  public async appleDigestLogin(api_access_token: string): Promise<void> {
    try {
      const response = await this.apiService.getUserWithApiAccessToken(api_access_token).toPromise();
      const user = User.createFromJson(response);

      if (user) {
        this.refreshUser(user);
        this.router.navigate(['/']);
      } else {
        this.refreshUser(null);
        this.router.navigate(['/']);
        this.appStateService.set(this.appStateService.OPEN_SIGNIN_WINDOW, 'digestLoginFailed', true);
      }
    } catch (e) {
      this.refreshUser(null);
      this.router.navigate(['/']);
      this.appStateService.set(this.appStateService.OPEN_SIGNIN_WINDOW, 'digestLoginFailed', true);
      throw e;
    }
  }


  logoutUser(): void {
    this.refreshUser(null);
  }

  modifyUserData(userID, user) {
    return this.apiService.modifyUser(userID, user)
  }

  changeUserPassword(userID, passwords: ChangeUserPassword) {
    return this.apiService.changePassword(userID, passwords);
  }

  getCurrentUser(): Promise<User> {
    return new Promise(resolve => resolve(this.currentUser));
  }

  public getLoginType(): LoginType {
    if (!this.currentUser) {
      return LoginType.Guest;
    }
    return this.currentUser.getLoginType();
  }
}
