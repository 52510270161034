import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GlobalEntityNavigationService} from '@core/components/global-entity-navigation/services/global-entity-navigation.service';
import {
  INavigationConfig,
  INavigationItem,
  NavigationItemChildAppearance
} from '@core/components/global-entity-navigation/models/navigation';
import {LayoutService} from '@utils/services/layout/layout.service';
import {Subscription} from 'rxjs';
import {Filter} from '@shared/components/filter/filter';
import {AnalyticsService} from '@utils/services/analytics/analytics.service';
import {NavigationHttpService} from '@core/components/global-entity-navigation/services/navigation-http.service';
import {IDefaultResponse} from '@api/models/default-response/response';
import {ManualScreenName} from '@utils/services/analytics/model/screen-name';

@Component({
  selector: 'app-navigation-trigger',
  templateUrl: './navigation-trigger.component.html',
  styleUrls: ['./navigation-trigger.component.scss']
})
export class NavigationTriggerComponent implements OnInit {

  displayState = NavigationTriggerDisplayState.Collapsed;
  selectionState = NavigationTriggerSelectionState.Inactive;
  currentLabel: string;

  loadingGroups: boolean;
  childTriggerConfig: INavigationConfig;

  itemClick$: Subscription;

  @Input() config: INavigationConfig;
  @Input() parentConfig: INavigationConfig;
  @Output() toggle = new EventEmitter<NavigationTriggerComponent>();

  childTrigger: NavigationTriggerComponent;
  @ViewChild(NavigationTriggerComponent) set childTriggerInit(childTrigger: NavigationTriggerComponent) {
    if (childTrigger) {
      childTrigger.open();
      this.itemClick$?.unsubscribe();
      this.toggle.emit(childTrigger);
    }

    this.childTrigger = childTrigger;
  }

  get collapsed() {return this.displayState === NavigationTriggerDisplayState.Collapsed}
  get expanded() {return this.displayState === NavigationTriggerDisplayState.Expanded}
  get inactive() {return this.selectionState === NavigationTriggerSelectionState.Inactive}
  get selected() {return this.selectionState === NavigationTriggerSelectionState.Selected}

  constructor(private categoryNavigationService: GlobalEntityNavigationService,
              public element: ElementRef,
              private navService: GlobalEntityNavigationService,
              private navHttpService: NavigationHttpService,
              private layoutService: LayoutService,
              private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.currentLabel = this.config.label;
  }

  private async handleNavigationItemClick(navigationItem: INavigationItem): Promise<void> {
    if (navigationItem.has_children && navigationItem.child_appearance === NavigationItemChildAppearance.NewList) {
      await this.createChildTrigger(navigationItem);
    }
  }

  public async refreshConfig(sourceItem: INavigationItem, filter?: Filter): Promise<void> {
    const config = await this.navHttpService.getChildrenForItem(sourceItem, filter);
    this.config = config.data;
    this.config.source_item = sourceItem;

    if (filter) {
      this.sendPageView();
    }
  }

  private async createChildTrigger(navigationItem: INavigationItem): Promise<void> {
    const childInfo = await this.navHttpService.getChildrenForItem(navigationItem);
    const childNavigationConfig: INavigationConfig = childInfo.data;

    childNavigationConfig.label = 'Bewerb wählen';

    this.childTriggerConfig = childNavigationConfig;
    this.childTriggerConfig.source_item = navigationItem;

    this.currentLabel = navigationItem.label;
    this.selectionState = NavigationTriggerSelectionState.Selected;

    this.close();

    this.itemClick$?.unsubscribe();

    if (this.childTrigger) {
      this.childTrigger.open();
      this.toggle.emit(this.childTrigger);
    }
  }

  public async handleClick(): Promise<void> {
    if (this.displayState === NavigationTriggerDisplayState.Collapsed) {
      if (!this.config?.groups) {
        this.loadingGroups = true;
        const contentGroups: IDefaultResponse = await this.navHttpService.getNavigationItems(this.config);
        this.config.groups = contentGroups.data.groups;
        this.loadingGroups = false;
      }

      this.childTrigger?.close();
      this.open();

    } else {
      this.childTrigger?.close();
      this.close();
    }

    this.toggle.emit(this);
  }

  public close(): void {
    this.childTrigger?.close();
    this.itemClick$?.unsubscribe();
    this.displayState = NavigationTriggerDisplayState.Collapsed;
  }

  public open(sendPageView = false): void {
    if (!this.itemClick$ || this.itemClick$.closed) {
      this.itemClick$ = this.navService.itemClicked
        .subscribe((navItem: INavigationItem) => this.handleNavigationItemClick(navItem));
    }

    if (sendPageView) {
      this.sendPageView();
    }

    this.displayState = NavigationTriggerDisplayState.Expanded;
  }

  private sendPageView(): void {
    const urlParams = new URLSearchParams();
    urlParams.set('navTrigger', this.config.type || this.parentConfig?.type);

    this.analyticsService.sendPageView({ manualScreenName: ManualScreenName.NAVIGATION})
  }
}

export enum NavigationTriggerDisplayState {
  Collapsed = 'collapsed',
  Expanded = 'expanded'
}

export enum NavigationTriggerSelectionState {
  Inactive = 'inactive',
  Selected = 'selected'
}
