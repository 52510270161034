import { Injectable } from '@angular/core';
import {AnalyticsService} from '@utils/services/analytics/analytics.service';
import {UserHttpService} from '@api/http/user/user-http.service';
import {UserService} from '@core/services/user.service';
import {EntityType} from '@lib/models/entity/entity-type';
import {User} from '@shared/models/user';
import {SnackbarService} from '@core/services/snackbar.service';
import {StateService} from '@core/services/state.service';
import {Subscription} from 'rxjs';
import {ManualScreenName} from '@utils/services/analytics/model/screen-name';

@Injectable({
  providedIn: 'root',
})
export class FollowService {

  constructor(
    private analyticsService: AnalyticsService,
    private userHttpService: UserHttpService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private appStateService: StateService,
  ) {}

  public async toggleFollow(follow: boolean, type: EntityType, name: string, id: string): Promise<boolean> {
    if (!this.user) {
      this.appStateService.set(this.appStateService.OPEN_SIGNIN_WINDOW, true, true);
      return false;
    }
    const isAlreadyFollowing = this.isAlreadyFollowing(type, id);
    if ((follow && isAlreadyFollowing) || (!follow && !isAlreadyFollowing)) {
      return false;
    }

    // Send page impression
    const screenName = follow ? ManualScreenName.FOLLOW : ManualScreenName.UNFOLLOW;
    this.analyticsService.sendPageView({ manualScreenName: screenName })

    const success = await this.performFollowAction(follow, type, name, id);
    if (success) {
      this.updateFollowList(type, id, follow);
    }
    return success;
  }

  private isAlreadyFollowing(type: EntityType, id: string): boolean {
    if (!this.user) {
      return false;
    }

    switch (type) {
      case EntityType.League:
        return this.user.isFanOfLeagueWithId(id);
      case EntityType.Team:
        return this.user.isFanOfTeamWithId(id);
      default:
        return false;
    }
  }

  private async performFollowAction(follow: boolean, type: EntityType, name: string, id: string): Promise<boolean> {
    try {
      const response = follow === true
        ? await this.userHttpService.followEntity(type, id)
        : await this.userHttpService.unfollowEntity(type, id);

      const success = response?.success ?? false;
      if (success) {
        const snackbarText = follow === true
          ? `Du folgst jetzt ${name}`
          : `Du folgst ${name} nicht mehr`;

        this.snackbarService.showSimpleSnackbar(snackbarText);
      }
      return response?.success ?? false;
    } catch (e) {
      this.snackbarService.showSimpleSnackbar(`Du folgst ${name} bereits.`);
    }
  }

  private updateFollowList(type: EntityType, id: string, isAdding: boolean): void {
    const followList = type === EntityType.League
      ? this.user.following.leagueids
      : this.user.following.teamids;

    if (isAdding) {
      followList.push(id);
    } else {
      this.removeEntityIdFromArray(followList, id);
    }
  }

  private removeEntityIdFromArray(array: string[], id: string): void {
    const index = array.indexOf(id);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  public userFollows(type: EntityType, id: string): boolean {
    if (!this.user || !id) {
      return false;
    }
    switch (type) {
      case EntityType.League:
        return this.user.isFanOfLeagueWithId(id);
      case EntityType.Team:
        return this.user.isFanOfTeamWithId(id);
      default:
        return;
    }
  }

  private get user(): User {
    return this.userService.currentUser;
  }
}
