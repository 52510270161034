import {EntityType} from '@lib/models/entity/entity-type';

export class Entity {
  type: EntityType;
  id: string;
  title: string;
  gender?: string;
  leagueName?: string;
  leagueId?: string;
  countryName?: string;
  stateName?: string;
  performanceLevel?: number;
  paywall?: boolean;
  phase?: string;
  live?: boolean;

  constructor(data: Partial<Entity> = {}) {
    Object.assign(this, data);
  }

  public getGenderString(): string | null {
    if (!this.gender) {
      return null;
    }
    const genderMap: Record<string, string> = {
      male: 'Herren',
      female: 'Damen',
      youth: 'Jugend',
    };

    return genderMap[this.gender] || null;
  }
}
