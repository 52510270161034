import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthDialogService} from '@modules/dialog/services/auth-dialog.service';

export enum DialogHashRoute {
  RECRUIT = 'werde-botschafter',
  REGISTER = 'registrieren',
  LOGIN = 'anmelden',
  RESET_PASSWORD = 'passwort-vergessen',
}

export const DialogHashRoutes = [
  DialogHashRoute.RECRUIT,
  DialogHashRoute.REGISTER,
  DialogHashRoute.LOGIN,
  DialogHashRoute.RESET_PASSWORD,
]

@Injectable({
  providedIn: 'root'
})
export class DialogHashRouteService {

  constructor(
    private authDialogService: AuthDialogService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.handleRoute(ev.urlAfterRedirects);
      }
    });
  }

  private handleRoute(route: string): void {
    if (route.endsWith('#' + DialogHashRoute.REGISTER)) {
      this.openRegisterDialog();
    }
    if (route.endsWith('#' + DialogHashRoute.LOGIN)) {
      this.openLoginDialog();
    }
    if (route.endsWith('#' + DialogHashRoute.RESET_PASSWORD)) {
      this.openResetPasswordDialog();
    }
  }

  private openRegisterDialog(): void {
    this.authDialogService.openRegistration({ registration: true });

  }

  private openLoginDialog(): void {
    this.authDialogService.openLogin();
  }

  private openResetPasswordDialog(): void {
    this.authDialogService.openResetPassword();
  }

  private removeFragment(): void {
    this.router.navigate([], {
      relativeTo: this.route, preserveFragment: false, replaceUrl: true
    });
  }

}
