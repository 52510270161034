import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {TeamService} from './team.service';
import {APIService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Picture} from './picture';
import {AppLeague} from '@shared/models/league';
import {StateService} from '@core/services/state.service';
import {AppTeam} from '@shared/models/team';
import {TypeService} from '@core/services/type.service';
import {PageType} from '../../../page-type';
import {isPlatformBrowser} from '@angular/common';
import {LinkService} from '@core/services/link.service';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {Response} from 'express';
import {SERVER_SET_REDIRECT} from '@core/server-injection-tokens';
import {LeagueHttpService} from '@api/http/league/league-http.service';
import {ILeagueTabsInfo} from '@api/models/tab-info/league-tabs-response';
import {LeagueRoutes} from '@app/routes/league.routes';
import {ILeagueStatistic} from '@api/models/statistics/league-statistics-response';
import {LeagueStatisticType} from '@api/models/statistics/league-statistic-type';
import {OverlayService} from '@lib-modules/overlays/overlay.service';
import {ISeason} from '@api/models/season/season';
import {PickerItem, PickerItemType} from '@lib-modules/picker/components/picker-item/picker-item.component';
import {IPicture} from '@api/models/image/picture';
import {ImageSubType} from '@api/models/image/image-type';
import {AnalyticsService} from '@utils/services/analytics/analytics.service';

export interface StructuredLeague {
  league: AppLeague;
  subLeagues: StructuredLeague[];
}

@Injectable({
  providedIn: 'root',
})
export class LeagueService {
  public currentLeague: AppLeague;
  public selectedSeason: ISeason;
  public teamsOfCurrentLeague: AppTeam[] = [];
  public tabs = [];
  public leagueStatistics: ILeagueStatistic[];
  private leagueCache: Map<string, AppLeague> = new Map();
  private leaguesRoute = 'leagues/';
  private tabInfo: ILeagueTabsInfo;

  constructor(
    private http: HttpClient,
    private leagueHttpService: LeagueHttpService,
    private apiService: APIService,
    private teamService: TeamService,
    private appStateService: StateService,
    private overlayService: OverlayService,
    @Optional() @Inject(RESPONSE) private serverResponse: Response<any>,
    @Optional()
    @Inject(SERVER_SET_REDIRECT)
    private setRedirect: (oldRid: string, redirectRid: string) => void,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  private structureLeagues(response: any[]): StructuredLeague[] {
    const leaguesWithOutSubLeagues: AppLeague[] = [];
    const leaguesWithSubLeagues: AppLeague[] = [];
    const leaguesWithSubLeaguesAndUpperLeague: AppLeague[] = [];
    const structuredLeagues: StructuredLeague[] = [];

    response.forEach((leagueJson) => {
      const league = this.resolveLeague(leagueJson);
      if (league.has_subleagues === true) {
        if (league.upperleagueid != null) {
          leaguesWithSubLeaguesAndUpperLeague.push(league);
        } else {
          leaguesWithSubLeagues.push(league);
        }
      } else {
        leaguesWithOutSubLeagues.push(league);
      }
    });

    leaguesWithOutSubLeagues.forEach((league) => {
      let parentLeague: AppLeague = null;
      parentLeague = leaguesWithSubLeaguesAndUpperLeague.find(
        (it) => it.id === league.upperleagueid,
      );
      if (!parentLeague) {
        parentLeague = leaguesWithSubLeagues.find(
          (it) => it.id === league.upperleagueid,
        );
      }
      if (parentLeague) {
        if (structuredLeagues.find((it) => it.league.id === parentLeague.id)) {
          structuredLeagues
            .find((it) => it.league.id === parentLeague.id)
            .subLeagues.push({league: league, subLeagues: []});
        } else {
          const subLeagues = [];
          subLeagues.push({league: league, subLeagues: []});
          structuredLeagues.push({
            league: parentLeague,
            subLeagues: subLeagues,
          });
        }
      } else {
        structuredLeagues.push({
          league: league,
          subLeagues: [],
        });
      }
    });

    return structuredLeagues;
  }

  public getCurrentLeagueId(): string | null {
    return this.currentLeague?.id || null;
  }

  public getCurrentLeagueReadableId(): string | null {
    return this.currentLeague?.readable_id || null;
  }

  public async initCurrentLeague(readableId: string): Promise<void> {
    if (TypeService.pageType !== PageType.League) {
      return;
    }
    if (!readableId || readableId.length === 0) {
      return;
    }
    this.leagueCache.clear();
    try {
      this.currentLeague = await this.getLeagueWithRid(readableId);
      await this.createLeagueTabs(this.currentLeague._id);
      if (this.tabInfo.statistics.exists) {
        this.leagueStatistics = [];
        const statistics = await this.leagueHttpService.getStatistics(
          this.currentLeague._id,
        );
        Object.values(LeagueStatisticType).forEach((value) => {
          if (statistics[value]) {
            statistics[value].type = value;
            this.leagueStatistics.push(statistics[value]);
          }
        });
      }
      this.teamsOfCurrentLeague = await this.fetchLeagueTeams(
        this.currentLeague.id,
      );
    } catch (e) {
      throw e;
    }
  }

  public async createLeagueTabs(leagueId: string): Promise<void> {
    const tabInfo = await this.leagueHttpService.getLeagueTabs(leagueId);
    this.tabInfo = tabInfo;
    this.tabs = [
      {name: 'Home', link: '/', exact: true, exists: tabInfo.home.exists},
      {name: 'Videos', link: `${LeagueRoutes.videos.root}`, exists: true},
      {name: 'Spiele', link: `${LeagueRoutes.games.root}`, exists: tabInfo.events.exists},
      {name: 'Tabelle', link: `${LeagueRoutes.table}`, exists: tabInfo.table.exists},
      {name: 'News', link: `${LeagueRoutes.news.root}`, exists: tabInfo.news.exists},
      {name: 'Transfers', link: `${LeagueRoutes.transfers}`, exists: tabInfo.transfers.exists},
      {name: 'Statistiken', link: `${LeagueRoutes.statistics}`, exists: tabInfo.statistics.exists},
      {name: 'Teams', link: `${LeagueRoutes.clubTeams}`, exists: tabInfo.teams.exists},
    ];
  }

  public getLeague(id: string): Promise<AppLeague> {
    return new Promise((resolve, reject) => {
      if (this.leagueCache && this.leagueCache.get(id)) {
        resolve(this.leagueCache.get(id));
      } else {
        resolve(this.fetchLeague(id));
      }
    });
  }

  /***************************** HTTP GET FUNCTIONS *****************************/

  private async getLeagueWithRid(rid: string): Promise<AppLeague> {
    try {
      const url = this.apiService.getBaseURL() + 'readable-id/' + rid;
      const response: any = await this.http
        .get(url, this.apiService.getJsonOptions())
        .toPromise();
      if (!response.success || !response.data) {
        console.error('Could not get data for readable id', rid);
        return null;
      }
      if (response.data.forward && !isPlatformBrowser(this.platformId)) {
        this.serverResponse.redirect(
          LinkService.getLinkForSubdomain(response.data?.forward.readable_id),
        );
        this.setRedirect(rid, response.data.forward.readable_id);
        return null;
      }
      return this.resolveLeague(response.data.object);
    } catch (e) {
      this.handleError(e);
    }
  }

  private fetchLeague(id: string): Promise<AppLeague> {
    return this.http
      .get(this.getLeagueRoute(id), this.apiService.getJsonOptions())
      .toPromise()
      .then((response: Response) => {
        const league = this.resolveLeague(response);
        this.leagueCache.set(league.id, league);
        return league;
      })
      .catch((error) => {
        console.log('League exists but could not be found', error);
        return null;
      });
  }

  async getLeagueOfTeam(teamId: string): Promise<AppLeague> {
    const team = await this.teamService.getTeam(teamId);
    const leagueId = team.getLeagueForTable();
    if (leagueId) {
      return this.getLeague(team.getLeagueForTable())
    }
    return null;
  }

  getCurrentTopTeamsAsPickerItems(): PickerItem[] {
    const pickerItems: PickerItem[] = this.teamsOfCurrentLeague.map(team => team.getAsPickerItem());
    const leaguePickerItem = this.currentLeague.getAsPickerItem();
    leaguePickerItem.name = 'Alle';
    leaguePickerItem.logo = null;
    pickerItems.unshift(leaguePickerItem);
    return pickerItems;
  }

  fetchStructuredLeaguesOfState(
    stateId: string,
    sex: string,
  ): Promise<AppLeague[]> {
    const headers = this.apiService.getJsonOptions();

    return this.http
      .get(
        this.apiService.getBaseURL() +
        'leagues/structure/state/' +
        stateId +
        '/' +
        sex,
        headers
      )
      .toPromise()
      .then((response: Response) => {
        if (!Array.isArray(response)) {
          throw new Error();
        }

        const leagues: AppLeague[] = [];
        response.forEach((leagueJson) => {
          leagues.push(this.resolveLeague(leagueJson));
        });

        return leagues;
      })
      .catch((error) => {
        console.log(error);
        throw new Error('League exists but could not be found');
      });
  }

  fetchSubLeaguesOfLeague(leagueId: string): Promise<AppLeague[]> {
    const headers = this.apiService.getJsonOptions();

    return this.http
      .get(
        this.apiService.getBaseURL() + 'leagues/' + leagueId + '/subleagues',
        headers
      )
      .toPromise()
      .then((response: Response) => {
        if (!Array.isArray(response)) {
          throw new Error();
        }
        const subLeagues: AppLeague[] = [];
        response.forEach((leagueJson) => {
          subLeagues.push(this.resolveLeague(leagueJson));
        });
        return subLeagues;
      })
      .catch((error) => {
        console.log(error);
        throw new Error('League exists but could not be found');
      });
  }

  /**
   * Returns league with it's sub-leagues
   * @param leagueId
   */
  fetchStructuredSubLeaguesOfLeague(
    leagueId: string,
  ): Promise<StructuredLeague[]> {
    const headers = this.apiService.getJsonOptions();

    return this.http
      .get(
        this.apiService.getBaseURL() + 'leagues/' + leagueId + '/subleagues',
        headers
      )
      .toPromise()
      .then((response: Response) => {
        if (!Array.isArray(response)) {
          throw new Error();
        }
        return this.structureLeagues(response);
      })
      .catch((error) => {
        console.log(error);
        throw new Error('League exists but could not be found');
      });
  }

  fetchLeagueTeams(id: string): Promise<AppTeam[]> {
    if (id) {
      return this.http
        .get(this.getLeagueTeamsRoute(id), this.apiService.getJsonOptions())
        .toPromise()
        .then((response: Response) => this.resolveLeagueTeams(response))
        .catch(this.handleError);
    }
  }

  fetchTopLeagues(countryId: string, sex: string): Promise<AppLeague[]> {
    return this.http
      .get(
        this.getTopLeaguesRoute(countryId, sex),
        this.apiService.getJsonOptions(),
      )
      .toPromise()
      .then((resp) => {
        const leagues: AppLeague[] = [];
        if (!(resp instanceof Array)) {
          return [];
        }
        resp.forEach((league) => {
          leagues.push(this.resolveLeague(league));
        });
        return leagues;
      })
      .catch(this.handleError);
  }

  fetchPopularLeagues(countryId: string, limit: number): Promise<AppLeague[]> {
    return this.http
      .get(
        this.getPopularLeaguesRoute(countryId, limit),
        this.apiService.getJsonOptions(),
      )
      .toPromise()
      .then((resp) => {
        const leagues: AppLeague[] = [];
        if (!(resp instanceof Array)) {
          return [];
        }
        resp.forEach((league) => {
          leagues.push(this.resolveLeague(league));
        });
        return leagues;
      })
      .catch((error) => {
        this.handleError(error);
        return null;
      });
  }

  public fetchLeaguePreviewByPerformanceLevel(
    p_level: number,
    past: number,
    future: number,
    state: string = null,
  ) {
    // fetches table and x past, y future events of leagues with a given p_level
    return this.http
      .get(
        this.getLeaguesPreviewByPerformanceLevelRoute(
          p_level,
          past,
          future,
          state,
        ),
        this.apiService.getJsonOptions(),
      )
      .toPromise()
      .then((r) => {
        const response = r as any;
        const leagueJson = response.data[0];

        leagueJson.id = leagueJson.league_id;
        leagueJson.name = leagueJson.league_name;
        leagueJson.leagueType = leagueJson.leagueType || 0;
        leagueJson.table = leagueJson.tables[0];

        const league = this.resolveLeague(leagueJson);
        const events = [];
        events.push(...response.data[0].upcoming);
        events.push(...response.data[0].results);

        return {league: league, events: events};
      })
      .catch((error) => {
        this.handleError(error);
        return {success: false, id: null, name: null, table: null};
      });
  }

  /***************************** RESOLVE FUNCTIONS *****************************/
  resolveLeague(jsonLeague): AppLeague {
    const league = new AppLeague(jsonLeague);

    if (jsonLeague.pictures != null) {
      for (let i = 0; i < jsonLeague.pictures.length; i++) {
        const pic = new Picture(jsonLeague.pictures[i]);
        league.pictures.push(pic);
      }
    }

    if (jsonLeague.ui_names != null) {
      league.ui_names.state_name = jsonLeague.ui_names.state_name;
      league.ui_names.country_name = jsonLeague.ui_names.country_name;
      league.ui_names.followers = jsonLeague.ui_names.followers;
    }

    return league;
  }

  resolveLeagueTeams(jsonTeams) {
    return this.teamService.resolveTeams(jsonTeams);
  }

  /***************************** ROUTE DEFINITIONS *****************************/
  getLeagueRoute(id: string): string {
    return this.apiService.getBaseURL() + this.leaguesRoute + id;
  }

  getLeagueTeamsRoute(id: string) {
    return this.getLeagueRoute(id) + '/teams?sorted=true';
  }

  getTopLeaguesRoute(countryId: string, sex: string) {
    return (
      this.apiService.getBaseURL() + `leagues/top/country/${countryId}/${sex}`
    );
  }

  getPopularLeaguesRoute(countryId: string, limit: number) {
    // return this.apiService.getBaseURL() + `countries/${countryId}/popularleagues?limit=${limit}`;
    return this.apiService.getBaseURL() + `v2/leagues/popular?limit=${limit}`;
  }

  private getLeaguesPreviewByPerformanceLevelRoute(
    p_level: number,
    past: number,
    future: number,
    state: string,
  ) {
    const stateParam = state ? '&stateid=' + state : '';
    return (
      this.apiService.getBaseURL() +
      `v2/leagues/overview/${p_level}/${past}/${future}/?random=true${stateParam}`
    );
  }

  handleError(error) {
    console.log(error);
    return error;
  }
}
