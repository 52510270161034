import {EventEmitter, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {AdService} from '@lib-modules/ads/services/ad.service';
import {SportTypeService} from '@api/services/sport-type/sport-type.service';
import {Entity} from '@lib/models/entity/entity';
import {ManualScreenName, ScreenNameParser} from '@utils/services/analytics/model/screen-name';
import {environment} from '../../../../../../../src/environments/environment';
import {EnvName} from '@utils/models/environment/env-name';
import { LoginType } from '@api/models/user/user';

declare let gtag: Function;

enum GAProperty {
  Android = 'G-P0DNVJD41W',
  AndroidDebug = 'G-GLK5KB6R5N',
  IOS = 'G-8XQ6C4FKJT',
  IOSDebug = 'G-8QFT46XW40',
  Web = 'G-718QDWDD3E', // fan.at - APP only - Web Datastream
}

export class PageViewOptions {
  url?: string;
  queryParams?: URLSearchParams;
  blockEvent?: boolean;
  refreshAds?: boolean;
  manualScreenName?: ManualScreenName;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  // Content grouping info
  public static currentEntity: Entity = null;
  public static currentUserHasSubscription = false;
  public static currentUserLoginType = LoginType.Guest;

  public pageViewEvent = new EventEmitter<string>();
  private isWebview = false;
  private ignoredRoutes: string[] = [];

  constructor(
    private router: Router,
    private adService: AdService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  public init(isWebview: boolean, ignoredRoutes: string[] = []) {
    this.isWebview = isWebview;
    this.ignoredRoutes = ignoredRoutes;
  }

  public onRouteChange(url: string): void {
    if (!isPlatformBrowser(this.platformId) || this.checkIgnoredNavigation()) {
      return;
    }
    this.sendPageView({ url: url });
  }

  public sendPageView(options?: PageViewOptions) {
    options = options || {};
    options.url = options.url || this.router.url;
    if (options.queryParams) {
      const queryString = options.url.includes('?')
        ? `&${options.queryParams.toString()}`
        : `?${options.queryParams.toString()}`;
      options.url += queryString;
    }
    if (!options.blockEvent) {
      this.pageViewEvent.next(options.url);
    }
    if (environment.name !== EnvName.Prod) {
      return;
    }
    if (options.refreshAds) {
      this.adService.refreshAll();
    }
    if (!this.isWebview) {
      this.sendGAPageView(options);
    }
  }

  private sendGAPageView(options: PageViewOptions): void {
    if (typeof gtag === 'undefined' || !gtag) {
      console.warn('[Google Tag] Could not send page view! gtag is undefined!');
      return;
    }
    this.getEventConfig(options).then((config) => {
      // console.log('DBG page view:', config);
      gtag('event', 'screen_view', config);
    });
  }

  private async getEventConfig(options: PageViewOptions): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const eventConfig: any = {
          page_path: options.url,
          anonymize_ip: true,
          send_to: GAProperty.Web,
          sports: SportTypeService.getSelectedSportsTypeString(),
          web_view: false, // exclusive to apps
          ads: true, // every view has ads
          notifications_enabled: false, // exclusive to apps
        };

        const notSet = '(not set)'; // default null value in google analytics
        const entity = AnalyticsService.currentEntity;
        const screenName = options.manualScreenName || ScreenNameParser.getScreenName(entity, options.url);
        eventConfig.screen_name = screenName;
        eventConfig.content_group = screenName;
        eventConfig.parent_view = ScreenNameParser.getParentScreenName(entity);
        eventConfig.login_type = AnalyticsService.currentUserLoginType;
        eventConfig.user_has_subscription = AnalyticsService.currentUserHasSubscription;

        // Entity specific infos
        if (entity) {
          eventConfig.content_group = entity.title || screenName;
          eventConfig.entity = entity.title;
          eventConfig.id = entity.id || notSet;
          eventConfig.gender = entity.getGenderString() || notSet;
          eventConfig.league_name = entity.leagueName || notSet;
          eventConfig.league_id = entity.leagueId || notSet;
          eventConfig.country = entity.countryName || notSet;
          eventConfig.state = entity.stateName || notSet;
          eventConfig.performance_level = entity.performanceLevel || notSet;
          eventConfig.paywall = entity.paywall || false;
          eventConfig.phase = entity.phase || notSet;
          eventConfig.is_live = entity.live || false;
        }

        resolve(eventConfig);
      }, 500);
    });
  }


  private checkIgnoredNavigation(): boolean {
    const url = window.location.href;
    let ignore = url.endsWith('#');
    this.ignoredRoutes.forEach((route) => {
      if (url.includes(route)) {
        ignore = true;
      }
    });
    return ignore;
  }
}
