import {AppTeam} from './team';
import {Picture} from '@core/services/picture';
import {ITableData} from '@api/models/table/table';
import {League} from '@api/models/league/league';
import {ISeason} from '@api/models/season/season';
import {PickerItem, PickerItemType} from '@lib-modules/picker/components/picker-item/picker-item.component';
import {IPicture} from '@api/models/image/picture';
import {ImageSubType} from '@api/models/image/image-type';
import {EntityType} from '@lib/models/entity/entity-type';
import {Entity} from '@lib/models/entity/entity';

interface LeagueUIName {
  state_name: string;
  country_name: string;
  followers: string;
}

export interface LeagueInfo {
  league_name: string;
  league_performance_level: number;
  league_type: number;
  video_ticker: string;
  league_readable_id: string;
}

export enum LeaguePerformanceGroup {
  KM = 'KM',
  Res = 'Res'
}

export enum LeagueCategory {
  ClubNational = 'club_national',
  ClubInternational = 'club_international',
  International = 'international',
  Friendly = 'friendly',
  Simulated = 'simulated'
}

/*export class LeagueType {
  // TODO why do we need the string values here? BE seems to deliver 0,1,2,3 ?
  static readonly LEAGUE  = new LeagueType('LEAGUE', 0);
  static readonly CUP  = new LeagueType('CUP', 1);
  static readonly RELEGATION  = new LeagueType('RELEGATION', 2);
  static readonly FRIENDLY  = new LeagueType('FRIENDLY', 3);

  static fromValue(value: number): LeagueType {
    if (value === LeagueType.LEAGUE.value) {
      return LeagueType.LEAGUE;
    } else if (value === LeagueType.CUP.value) {
      return LeagueType.CUP;
    } else if (value === LeagueType.RELEGATION.value) {
      return LeagueType.RELEGATION;
    } else if (value === LeagueType.FRIENDLY.value) {
      return LeagueType.FRIENDLY;
    } else {
      return null;
    }
  }

  // private to disallow creating other instances of this type
  private constructor(private readonly key: string, public readonly value: any) {}
}*/

export const leagueLogoPlaceholderImage = 'assets/icons/fan.at/shield_gray.svg';

export class AppLeague extends League {
  id: string;
  name: string;
  sponsor: string;
  teams: AppTeam[];
  followers: number;
  tableurl: string;
  table: ITableData;
  pictures: Picture[];
  calculate_table: boolean;
  category: LeagueCategory;
  readable_id: string;
  has_subleagues: boolean;
  upperleagueid: string;
  countryid: string;
  performance_level: number;
  stateids: string[];
  state_names: string[];
  ui_names: LeagueUIName;
  league_info: LeagueInfo;

  public constructor(init?: Partial<AppTeam>) {
    super(init);
    Object.assign(this, init);
    this.pictures = [];
    if (init['_id']) {
      this.id = init['_id'];
    }

    if (init.pictures) {
      this.pictures = init.pictures.map((pic) => new Picture(pic));
    }
  }

  getLogoImageUrl(size: string): string {
    if (this.pictures == null || this.pictures.length === 0) {
      return leagueLogoPlaceholderImage;
    }

    const logoImg = this.pictures.find( picture => { return picture.subtype === 'logo'});
    if (logoImg == null) {
      return leagueLogoPlaceholderImage;
    }

    if (size === 'small') {
      return logoImg.getSmallImage().url;
    } else if (size === 'medium') {
      return logoImg.getMediumImage().url;
    } else if (size === 'big') {
      return logoImg.getBigImage().url;
    }

    return leagueLogoPlaceholderImage;
  }

  getUIStateName(): string {
    if (this.ui_names == null || !this.ui_names.state_name) {
      return null;
    }

    return this.ui_names.state_name;
  }

  getUICountryName(): string {
    if (this.ui_names == null || !this.ui_names.country_name) {
      return null;
    }

    return this.ui_names.country_name;
  }

  getFirstSeasonInArray(): ISeason {
    if (this.seasons.length === 0) {
      return null;
    }
    return this.seasons[0];
  }

  getStateId(): string {
    if (this.stateids.length > 0) {
      return this.stateids[0];
    }

    return null;
  }

  getAsPickerItem(): PickerItem {
    let logo: IPicture;
    if (this.pictures.length > 0) {
      logo = this.pictures.find(picture => picture.subtype === ImageSubType.LOGO);
    }
    return {
      objectId: this.id,
      name: this.name,
      readableId: this.readable_id,
      type: PickerItemType.League,
      logo: logo,
    };
  }

  getAsEntity(): Entity {
    return new Entity({
      id: this.id,
      title: this.name,
      type: EntityType.League,
      leagueName: this.name,
      leagueId: this.id,
      gender: this.sex,
      stateName: this.ui_names.state_name,
      countryName: this.ui_names.country_name,
      performanceLevel: this.performance_level,
    });
  }
}
