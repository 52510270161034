import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '@app/core/services/user.service';
import {EntityType} from '@lib/models/entity/entity-type';
import {FollowService} from '@core/services/follow/follow.service';

@Component({
  selector: 'app-follow-button',
  templateUrl: './follow-button.component.html',
  styleUrls: ['./follow-button.component.scss']
})
export class FollowButtonComponent implements OnInit {

  @Input() type: EntityType;
  @Input() id: string;
  @Input() name: string;
  isFollowing: boolean = false;
  loading = false;

  constructor( private followService: FollowService) { }

  ngOnInit(): void {
    this.isFollowing = this.followService.userFollows(this.type, this.id);
  }

  public async toggleFollow(): Promise<void> {
    if (this.loading === true) {
      return;
    }

    await this.followService.toggleFollow(!this.isFollowing, this.type, this.name, this.id);
    this.isFollowing = !this.isFollowing;
    this.loading = false;
  }
}
