import { EntityType } from '@lib/models/entity/entity-type';
import { Entity } from '@lib/models/entity/entity';

// Home Tabs
export enum HomeScreenName {
  HOME = 'Startseite',
  VIDEOS = 'Videos',
  HANDBALL_VIDEOS = 'Handball Videos',
  HANDBALL_GAME = 'Handball Spiel',
  NEWS_DETAIL = 'Newsbeitrag',
  NEWS = 'Alle News',
  NEWS_MY = 'Meine News',
  VOTINGS = 'Votings',
  GAMES = 'Alle Spiele',
  GAMES_MY = 'Meine Spiele',
  FAVORITES = 'Favoriten',
  PAYWALL = 'Abo-Ansicht',
}

// Game Screens
export enum EventScreenName {
  OVERVIEW = 'Infos',
  LIVETICKER = 'Live-Ticker',
  VIDEOS = 'Handball Event',
  TABLE = 'Spieltabelle',
  LINEUP = 'Aufstellung',
}

// Team Screens
export enum TeamScreenName {
  HOME = 'Team Startseite',
  NEWS = 'Team News',
  GAMES = 'Team Spiele',
  VIDEOS = 'Team Videos',
  TABLE = 'Team Tabelle',
  SPONSORS = 'Team Sponsoren',
  SQUAD = 'Team Kader',
  INFOS = 'Team Infos',
  STATISTICS = 'Liga Statistiken',
  TEAMS = 'Team Teams',
  TRANSFERS = 'Team Transfers',
}

// League Screens
export enum LeagueScreenName {
  HOME = 'Liga Startseite',
  NEWS = 'Liga News',
  GAMES = 'Liga Spiele',
  VIDEOS = 'Liga Videos',
  TABLE = 'Liga Tabelle',
  SPONSORS = 'Liga Sponsoren',
  INFOS = 'Liga Infos',
  TEAMS = 'Liga Teams',
  STATISTICS = 'Liga Statistiken',
  TRANSFERS = 'Liga Transfers',
}

// Screen names of subviews
export enum ParentScreenName {
  HOME = 'home',
  EVENT = 'event',
  TEAM = 'team',
  LEAGUE = 'league',
}

// Manually triggered screen names
export enum ManualScreenName {
  VIDEOS = 'Video',
  VIDEO_SWITCH = 'Video-Switch',
  NAVIGATION = 'Navigation',
  FOLLOW = 'Folgen',
  UNFOLLOW = 'Entfolgen',
}

export class ScreenNameParser {
  public static getScreenName(entity: Entity, path: string): string {
    switch (entity?.type) {
      case EntityType.News:
        return HomeScreenName.NEWS_DETAIL;
      case EntityType.Event:
        return this.getEntityScreenName(path, EventScreenName);
      case EntityType.Team:
        return this.getEntityScreenName(path, TeamScreenName);
      case EntityType.League:
        return this.getEntityScreenName(path, LeagueScreenName);
      default:
        return this.getHomeScreenName(path);
    }
  }

  public static getParentScreenName(entity: Entity): ParentScreenName {
    switch (entity?.type) {
      case EntityType.Event:
        return ParentScreenName.EVENT;
      case EntityType.Team:
        return ParentScreenName.TEAM;
      case EntityType.League:
        return ParentScreenName.LEAGUE;
      default:
        return ParentScreenName.HOME;
    }
  }

  private static getEntityScreenName<T>(
    path: string,
    screenViewType: T
  ): string {
    const mapping: { [key: string]: T } = {
      videos: screenViewType['VIDEOS'],
      kader: screenViewType['SQUAD'],
      tabelle: screenViewType['TABLE'],
      news: screenViewType['NEWS'],
      transfers: screenViewType['TRANSFERS'],
      teams: screenViewType['TEAMS'],
      sponsoren: screenViewType['SPONSORS'],
      info: screenViewType['INFOS'],
      statistiken: screenViewType['STATISTICS'],
      uebersicht: screenViewType['OVERVIEW'],
      liveticker: screenViewType['LIVETICKER'],
      aufstellung: screenViewType['LINEUP'],
      spiele: screenViewType['GAMES'],
    };

    for (const key in mapping) {
      if (path.includes(key)) {
        return mapping[key] as any;
      }
    }
    return screenViewType['HOME'];
  }

  private static getHomeScreenName(_path: string): string {
    const path = _path.slice(1);
    if (path.length === 0) {
      return HomeScreenName.HOME;
    }

    const mapping: { [key: string]: HomeScreenName } = {
      videos: HomeScreenName.VIDEOS,
      'handball/videos/uebersicht': HomeScreenName.HANDBALL_VIDEOS,
      'handball/videos/': HomeScreenName.HANDBALL_GAME,
      spiele: HomeScreenName.GAMES,
      'meine-spiele': HomeScreenName.GAMES_MY,
      'news/meine': HomeScreenName.NEWS_MY,
      news: HomeScreenName.NEWS,
      votings: HomeScreenName.VOTINGS,
      favoriten: HomeScreenName.FAVORITES,
      abo: HomeScreenName.PAYWALL,
    };

    for (const key in mapping) {
      if (path.startsWith(key)) {
        return mapping[key];
      }
    }
    return HomeScreenName.HOME;
  }
}
