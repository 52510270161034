import { Injectable } from '@angular/core';
import {HttpService} from '@api/http/http.service';
import {IAdCampaignItem} from '@api/models/ad-campaign/ad-campaign';
import {IDefaultResponseT} from '@api/models/default-response/response';
import {AD_CAMPAIGNS_ROUTE} from '@api/http/http-routes';
import {EntityType} from '@lib/models/entity/entity-type';

type AdCampaignResponse = IDefaultResponseT<IAdCampaignItem[]>;

@Injectable({
  providedIn: 'root'
})
export class AdCampaignHttpService {

  constructor(private http: HttpService) { }

  public async getAdCampaignForEntity(entityId: string,
                                      entityType: EntityType,
                                      domain?: string): Promise<AdCampaignResponse> {
    const queryParams = [];
    if (domain) {
      queryParams.push(`domain=${domain}`);
    }
    return await this.http.get([AD_CAMPAIGNS_ROUTE, 'for', entityType, entityId], queryParams);
  }
}
