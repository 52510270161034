import {Injectable} from '@angular/core';
import {AdCampaignHttpService} from '@api/http/ad-campaign/ad-campaign-http.service';
import { IAdCampaignAdTargeting, IAdCampaignItem } from '@api/models/ad-campaign/ad-campaign';
import {Subject} from 'rxjs';
import {AdCampaignPlacement} from '@api/models/ad-campaign/ad-campaign-placement';
import {EntityType} from '@lib/models/entity/entity-type';

export interface IGoogleAdTargeting {
  channel_name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdCampaignService {

  public adTarget: IGoogleAdTargeting;
  public campaignChange = new Subject();
  public campaignReady = false;
  public campaign: IAdCampaignItem[];
  public showCampaign = true;
  private currenObjectId: string;
  private type: EntityType;
  private placement: AdCampaignPlacement;

  constructor( private adCampaignHttp: AdCampaignHttpService ) { }

  public async getVideoAdTargeting(videoId: string): Promise<IGoogleAdTargeting | null> {
    if (this.adTarget) {
      return this.adTarget;
    }
    if (!videoId?.length) {
      return null;
    }
    const response = await this.adCampaignHttp.getAdCampaignForEntity(videoId, EntityType.Video);
    if (!response.data?.length) {
      return null;
    }
    return this.findAdTargetingForType(response.data, EntityType.Video);
  }

  public getAdTargeting(): IGoogleAdTargeting | null {
    if (this.adTarget) {
      return this.adTarget;
    }
    if (!this.campaign || !this.type || !this.placement) {
      return null;
    }
    return this.findAdTargetingForType(this.campaign, this.type);
  }

  public setType(type: EntityType): void {
    this.type = type;
  }

  public hasTarget(): boolean {
    return !!this.type;
  }

  public setPlacement(placement: AdCampaignPlacement): void {
    this.placement = placement;
    if (this.campaign) {
      this.checkPlacements();
    }
  }

  public canShow(campaign: IAdCampaignItem): boolean {
    const placements = campaign.settings?.placements;
    if (!placements || !this.type) {
      return false;
    }
    const placementsForType = placements[this.type];
    if (!placementsForType) {
      return false;
    }
    return placementsForType[this.placement] ?? false;
  }

  public async getAdCampaignForEntity(id: string, type: EntityType, domain?: string): Promise<void> {
    if (this.currenObjectId === id) {
      return;
    }
    this.campaignReady = false;
    const response = await this.adCampaignHttp.getAdCampaignForEntity(id, type, domain);
    if (response && response.success) {
      this.campaign = response.data;
      this.type = type;
      this.currenObjectId = id;
      this.checkPlacements();
    }
    this.campaignReady = true;
    this.campaignChange.next(this.campaign);
  }

  public reset(): void {
    this.showCampaign = false;
    this.adTarget = null;
  }

  private checkPlacements(): void {
    this.showCampaign = this.campaign.some((item) => this.canShow(item));
  }

  private findAdTargetingForType(campaign: IAdCampaignItem[], type: EntityType): IGoogleAdTargeting | null {
    const campaignItem = campaign.find((item) => {
      if (!item.settings?.google_ad_targeting) {
        return false;
      }
      const targeting = item.settings.google_ad_targeting[type];
      if (!targeting) {
        return false;
      }
      return targeting[this.placement]?.length > 0 || targeting.default?.length > 0;
    });
    if (!campaignItem) {
      return null;
    }
    const targeting = campaignItem?.settings.google_ad_targeting
    if (targeting && targeting[type]) {
      const targetingPlacements = targeting[type];
      const placement = targetingPlacements[this.placement] ?? targetingPlacements.default;
      return { channel_name: targeting.channel_name,  value: placement };
    }
    return null;
  }
}
