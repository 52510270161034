import {Injectable} from '@angular/core';
import {HttpService} from '@api/http/http.service';
import {SUBSCRIPTIONS_ROUTE} from '@api/http/http-routes';
import {DefaultResponse} from '@api/models/default-response/response';
import { SubscriptionStatusResponseDto, SubscriptionStatusResponse } from './dto/subscription-status-response.dto';
import { SubscriptionProductResponseDto, SubscriptionProductsResponse } from './dto/subscription-products.dto';
import { PaymentUrlRequestDto } from './dto/payment-url-request.dto';
import { PaymentUrlResponseDto } from './dto/payment-url-response.dto';
import {SubscriptionUserInfoDto, SubscriptionUserInfoResponse} from '@api/http/subscriptions/dto/subsciption-user-info.dto';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsHttpService {

  constructor( private http: HttpService ) { }

  public async getPromoCodeForEntity(
    target: 'club', targetId: string
  ): Promise<DefaultResponse<{ promo_code: string }>> {
    return this.http.get(['coupons/promo-code', target, targetId]);
  }

  public async postRedeemCoupon(code: string): Promise<DefaultResponse<{ clubid: string }>> {
    return this.http.post<any>(
      ['coupons/redeem/user'],
      { promo_code: code }
    ).toPromise();
  }

  public async postAddUserTrialAbo(userId: string): Promise<DefaultResponse> {
    const until = Date.now() + 1000 * 60 * 60 * 24;
    return this.http.post<DefaultResponse>(
      [SUBSCRIPTIONS_ROUTE, 'user/abo/trial'],
      { userid: userId, free_abo_trial_until_ts: until }
    ).toPromise();
  }

  public async getSubscriptionStatus(userId: string): Promise<SubscriptionStatusResponseDto> {
    const response = await this.http.get<SubscriptionStatusResponse>(
      [SUBSCRIPTIONS_ROUTE, 'status/user', userId]
    );
    return response.data;
  }

  public async getUserSubscriptionInfo(userId: string): Promise<SubscriptionUserInfoDto> {
    const response = await this.http.get<SubscriptionUserInfoResponse>(
      [SUBSCRIPTIONS_ROUTE, 'management/user', userId]
    );
    return response.data;
  }

  public async getSubscriptionProducts(): Promise<SubscriptionProductResponseDto[]> {
    const response = await this.http.get<SubscriptionProductsResponse>([SUBSCRIPTIONS_ROUTE, 'products']);
    return response.data;
  }

  public async getPaymentUrl(
    productId: string, query?: PaymentUrlRequestDto
  ): Promise<PaymentUrlResponseDto> {
    const queryParams: string[] = [];
    if (query.clubId) {
      queryParams.push(`clubid=${query.clubId}`);
    }
    if (query.teamId) {
      queryParams.push(`teamid=${query.teamId}`);
    }
    if (query.promoCode) {
      queryParams.push(`promo_code=${query.promoCode}`);
    }
    const response = await this.http.get<DefaultResponse<PaymentUrlResponseDto>>(
      [SUBSCRIPTIONS_ROUTE, 'payment-url', productId], queryParams
    );
    return response.data;
  }
}
