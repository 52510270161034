export enum LoginType {
  Email = 'E-Mail',
  Facebook = 'Facebook',
  Guest = 'Gast',
}

export enum UserReporterType {
  Admin = 'admin',
  TeamReporter = 'team_reporter',
  NewsReporter = 'news_reporter',
  LiveReporter = 'fan_reporter',
  None = 'none'
}

export interface IUserFanModeratorProperty {
  eventid: string;
  teamid: string;
  role: UserReporterType;
}

export interface IUserModeratorProperty {
  action: string;
  communication: { email: IUserCommunicationProperty, notification: IUserCommunicationProperty };
  paid: boolean;
  role: UserReporterType;
  teamid: string;
}

interface IUserCommunicationProperty {
  general_information?: boolean;
  registration_release?: boolean;
  registration_start?: boolean;
  event_end_summary?: boolean;
  question_evaluation_reminder?: boolean;
  registration_reminder?: boolean;
  ticker_inactive?: boolean;
  ticker_reminder?: boolean;
  ticker_reminder_game_start?: boolean;
}

interface IUserEmailVerification {
  token: string;
  valid_until: number;
  verified: boolean;
  email_active: boolean;
  complaint: boolean;
  bounced: boolean;
  last_call: number;
  times_changed: number;
  notification1_sent: boolean;
  notification2_sent: boolean;
  notification3_sent: boolean;
  notification_blocked_sent: boolean;
}

export interface IUserPermissions {
  video_reporter?: {
    video_ticker: boolean;
    video_ticker_until: number;
  };
  liveticker?: {
    verified_reporter: boolean;
  };
  download_access?: boolean;
}

export interface IUser {
  _id: string;
  email: string;
  email_lowercase: string;
  firstname: string;
  lastname: string;
  username: string;
  moderator_of: IUserModeratorProperty[];
  fanmoderator_of: IUserFanModeratorProperty[];
  leagues_moderating?: string[];
  api_access_token: string;
  show_test_teams: boolean;
  locale: string;
  admin: boolean;
  internal: boolean;
  all_teams_access?: boolean;
  email_verification: IUserEmailVerification;
  privacy_policy_accepted?: string;
  moderation_ban_until?: string;
  reporter: {
    newsprofileids: string[];
  };
  permissions?: IUserPermissions;
  facebook_id?: string;
}
