import { IPicture } from '../image/picture';
import { IStadium } from '../stadium/stadium';
import { ISponsor } from '@api/models/sponsor/sponsor';
import { ITeamStaigeInfo } from './team-staige-info';
import { ITeamPixellotInfo } from './team-pixellot-info';
import { ITeamStreamSettings } from './team-stream-settings';

interface ITeamFrontendSettingsProperty {
  domain?: string;
  website_header?: boolean;
  custom_pages?: any[];
  colors: {
    background: string;
    foreground: string;
  };
}

interface ITeamUiNames {
  league_name?: string;
  state_name?: string;
  state_shortname?: string;
  country_name?: string;
}

interface ITeamMediaPage {
  name: string;
  url: string;
  symbol: string;
}

interface ITeamSportRadarField {
  sr_competitor_id: string;
  sr_api: string;
  sr_created: boolean;
  sr_update_history: [];
  sr_verified: boolean;
}

interface ITeamFoesSettings {
  foes_B_team: boolean;
  foes_alt_names: string[];
  foes_amateur_team: boolean;
  foes_club_nr: number;
  foes_created: boolean;
  foes_legacy_ical_urls: string[];
  foes_name: string;
  foes_seasons: string[];
  foes_sex: string;
  foes_team_ical_url: string;
  foes_update_history: [{ filename: string; timestamp: number }];
  foes_verified: boolean;
}

interface ITeamSettings {
  entity_names?: string[];
  video_ticker?: {
    supported: boolean;
  };
}

export interface ITeam {
  name?: string;
  type?: string;
  abbreviation?: string;
  frontend_settings?: ITeamFrontendSettingsProperty;
  fan_moderation_allowed?: boolean;
  stadium?: IStadium;
  ui_names?: any;
  sex?: string;
  followers?: number;
  performance_level?: number;
  pictures?: IPicture[];
  sponsors?: ISponsor[];
  sponsor?: ISponsor;
  international?: boolean;
  primary_color?: string;
}

export class Team implements ITeam {
  public static getTeamDetail(team: ITeam, short = false): string[] {
    const detailString = Team.getTeamDetailString(team, false, short);
    return detailString.split('·');
  }
  public static getTeamDetailString(
    team: ITeam,
    html = true,
    short = false,
    strongLeague = false
  ): string {
    if (!team.ui_names) {
      return '';
    }
    const data = team.ui_names;
    const separator = html ? '&#32;&#183;&#32;' : ' · ';
    let details = ``;
    details += data.state_name
      ? short
        ? data.state_shortname
        : data.state_name
      : 'Kein Bundesland';
    details += separator;
    const league =
      data.league_name && data.league_name.length > 0
        ? data.league_name
        : 'Keine Liga';
    if (strongLeague) {
      details += `<strong>${league}</strong>`;
    } else {
      details += league;
    }
    if (!short) {
      details += separator + team.type;
      details += separator + Team.getGenderString(team.sex);
    }
    return details;
  }

  public static getGenderString(sex: string): string {
    switch (sex) {
      case 'female':
        return 'Frauen';
      case 'male':
        return 'Herren';
      case 'youth':
        return 'Jugend';
    }
  }

  public _id: string;
  public _created: number;
  public name: string;
  public abbreviation: string;
  public leagueids: string[];
  public frontend_settings: ITeamFrontendSettingsProperty;
  public pictures: IPicture[];
  public stadium: IStadium;
  public homepage: string;
  public pages: ITeamMediaPage[];
  public ui_names: ITeamUiNames;
  public moderated: boolean;
  public trainers: any[];
  public fan_moderation_allowed: boolean;
  public short_url: string;
  public clubid: string;
  public sex: 'youth' | 'female' | 'male';
  public sportradar: ITeamSportRadarField;
  public type: string;
  public readable_id: string;
  public stateid: string;
  public performance_level: number;
  public sponsors: ISponsor[];
  public sponsor: ISponsor;
  public international: boolean;
  public settings?: ITeamSettings;
  public foes?: ITeamFoesSettings;
  public staige?: ITeamStaigeInfo;
  public pixellot?: ITeamPixellotInfo;
  public streaming_settings?: ITeamStreamSettings;
  public primary_color?: string;
  public followers?: number;

  constructor(data: Partial<Team>) {
    Object.assign(this, data);
    if (data.sponsor) {
      if (!this.sponsors) {
        this.sponsors = [data.sponsor];
      } else {
        this.sponsors.push(data.sponsor);
      }
    }
  }
}
